import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { ICONS } from "../../config/icons";
import REQUESTS from "../../api/requests";
import TEXT from "../../config/text";
import AddPlaylist from "./AddPlaylist";

import Playlist from "./Playlist";
import useTranslation from "../../hooks/useTranslation";

import style from "./style/style.module.scss";
import PlaylistEditor from "../../editors/PlaylistEditro";
import { useNavigate } from "react-router-dom";
import dateFormat from "../../config/dateFormat";

let logoutTimer;

export default function PlaylistsPage() {
    const { webSettings, isAdmin } = useSelector((state) => state.globalState);

    const translation = useTranslation();

    const [device, setDevice] = useState([]);
    const [deviceMac, setDeviceMac] = useState(null);

    const [visibleAddPlaylistModal, setVisibleAddPlaylistModal] = useState(false);

    const navigate = useNavigate();

    const handleText = (text) => {
        if (translation?.pages?.login?.[text]) {
            return translation?.pages?.playlist?.[text];
        }

        return TEXT?.pages?.playlist?.[text];
    };

    const getPlaylists = () => {
        REQUESTS.GET_PLAYLISTS(
            (data) => {
                setDevice(data);
                setDeviceMac(data.mac);
            },
            (err) => { }
        );
    };

    useEffect(() => {
        getPlaylists();
    }, []);

    const pageStyle = {
        backgroundColor: webSettings?.pages?.playlist?.background_color,
        backgroundImage: `url(${webSettings?.pages?.playlist?.background_image})`,
        backgroundSize: "cover",
    };

    function resetLogut() {
        clearTimeout(logoutTimer)

        logoutTimer = setTimeout(() => {
            localStorage.removeItem("TOKEN")
            navigate("/login")
        }, 10 * 60 * 1000);
    };

    useEffect(() => {
        document.body.addEventListener('mousemove', resetLogut);

        return () => {
            clearTimeout(logoutTimer);
            document.removeEventListener('mousemove', resetLogut);
        };
    }, [])

    console.log(device,'device')

    return (
        <div className={style["page"]} style={pageStyle}>
            {isAdmin && <PlaylistEditor />}
            <div className={style["playlist-page-container"]}>
                <h1 className={style["title"]}>{handleText("title")}</h1>
                {
                    device && device.mac &&
                    <div className={style["device-info"]}>
                        <div> <span>Mac Address :</span> {device.mac}</div>
                        <div> <span>Status :</span> {
                            !device.payed
                                ? device.free_trial < 2
                                    ? "Free trial"
                                    : "Free trial expired"
                                : "Activated"}
                        </div>
                        <div>
                            <span>{device.payed ? "Activation expired" : "Free trial expired"} : </span>
                            {dateFormat(device.payed ? device.activation_expired : device.free_trial_expired)}
                        </div>
                    </div>
                }

                <div className={style["playlists-container"]}>
                    {
                        device.playlists &&
                        device.playlists.map((playlist) => {
                            return (
                                <Playlist
                                    playlist={playlist}
                                    deviceId={device.id}
                                    getPlaylists={getPlaylists}
                                    key={playlist.id}
                                />
                            );
                        })
                    }
                    <div
                        className={style["playlist-add-btn"]}
                        onClick={() => setVisibleAddPlaylistModal(true)}
                    >
                        {ICONS.ADD}
                        <span> {handleText("button_text")}</span>
                    </div>
                </div>
                <AddPlaylist
                    visible={visibleAddPlaylistModal}
                    onCancel={() => setVisibleAddPlaylistModal(false)}
                    getPlaylists={getPlaylists}
                    deviceMac={deviceMac}
                />
            </div>
        </div>
    );
}
