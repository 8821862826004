import React from 'react'

import { Form, Input } from 'antd'

import ModalComponent from '../../../components/ModalComponent'

import ButtonComponent from '../../../components/ButtonComponent'

import style from "../style/payment.module.scss"

export default function PixiEmailModal({ onCancel, open, payByPixi }) {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('hh')
        onCancel()
        payByPixi(values.email)
    }

    return (
        <ModalComponent open={open} onCancel={() => {
            onCancel();
            form.resetFields();
        }}>
            <div className={style['pixi-email-container']}>
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={onFinish}
                >
                    <div >
                        <h3 className={style['pixi-email-title']}>Enter your email</h3>
                        <Form.Item
                            name="email"
                            // label="Email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid email!'
                                },
                                {
                                    required: true,
                                    message: 'Please input your email!'
                                }
                            ]}>
                            <Input
                                placeholder="Email"
                            />
                        </Form.Item>

                        <Form.Item  style={{textAlign:"center",margin:0}}>
                            <ButtonComponent
                                onClick={() => form.submit()}
                                title="Next"
                            />

                        </Form.Item>
                    </div>
                </Form>
            </div>
        </ModalComponent>
    )
}
